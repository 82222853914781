@use 'sass:math';

@import '../assets/styles/shared';

.descriptionList {
  margin-bottom: 0;

  dt {
    @include text-uppercase;

    font-weight: normal;
    opacity: math.div(2, 3);
  }

  dd,
  dt {
    font-size: $font-size-base;
  }

  dd:last-child {
    margin-bottom: 0;
  }
}
