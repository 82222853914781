// Remove borders on input groups

.input-group > .form-control:not(:last-child) {
  border-right: none;
}

.input-group > .form-control:not(:first-child) {
  border-left: none;
}

.input-group > .input-group-prepend .btn,
.input-group > .input-group-prepend .dropdown {
  @include border-left-radius($input-border-radius);
}

.input-group > .input-group-append .btn,
.input-group > .input-group-append .dropdown {
  @include border-right-radius($input-border-radius);
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-append > .btn {
  @include font-size($input-font-size);

  padding: $input-padding-y $input-padding-x;
  line-height: $input-line-height;
  background-color: $input-bg;
  border: $input-border-width solid $input-border-color;
}

.input-group > .input-group-append > .dropdown {
  @include font-size($input-font-size);

  line-height: $input-line-height;
  background-color: $input-bg;
  border: $input-border-width solid $input-border-color;

  // stylelint-disable-next-line selector-max-compound-selectors
  .btn {
    @include border-right-radius($input-border-radius);
    @include border-left-radius(0);

    height: 100%;
    padding: 10px $input-padding-x; // 10px = calc($input-padding-y - $input-border-width)
    border-color: transparent;
  }
}

@mixin input-group-validation-state($state, $color) {
  .input-group.is-#{$state} > .input-group-prepend,
  .input-group.is-#{$state} > .input-group-append,
  .input-group-prepend.is-#{$state},
  .input-group-append.is-#{$state} {
    border-color: $color;

    .btn {
      border-color: $color;
    }
  }
}

@each $state, $data in $form-validation-states {
  @include input-group-validation-state($state, map-get($data, color));
}
