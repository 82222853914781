@import '../../assets/styles/shared';

$timeout: 750ms * $animationTimeoutFactor;
$easing: $easeInOutQuad;

:export {
  timeout: strip-unit($timeout);
  easing: $easeInOutQuad;
}

// Base states
.expand {
  height: 0;
  overflow: hidden;
  transition: height $timeout $easing;
}

.expandEnterDone {
  overflow: inherit;
}
