@import './shared';

.nav-tabs {
  border-bottom: none;

  .nav-item {
    &:first-child {
      .nav-link {
        border-top-left-radius: 0.25rem;
      }
    }

    &:last-child {
      .nav-link {
        border-top-right-radius: 0.25rem;
      }
    }

    // stylelint-disable-next-line no-descending-specificity
    .nav-link {
      border-radius: 0;
      transition: 0.25s ease-in-out;

      &.active,
      &:hover {
        color: $white;
        background-color: $dark;
        border-color: transparent;
      }
    }
  }
}
