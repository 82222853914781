@import '../../../../styles/shared';

.dateButton {
  font-size: initial;

  @include media-breakpoint-down(md) {
    padding: 0.5rem 0.75rem;
  }

  &:focus {
    box-shadow: none;
  }
}
