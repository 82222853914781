@import '../../assets/styles/shared';

$timeout: 750ms * $animationTimeoutFactor;
$scale-diff: 0.5;

:export {
  timeout: strip-unit($timeout);
  easingIn: easeinquint;
  easingOut: easeoutquint;
  scale-diff: $scale-diff;
}

.dropIn {
  opacity: 0;

  &.dropInEnter {
    opacity: 0;
    transform: scale(1 + $scale-diff);
  }

  &.dropInEnterActive {
    transition: transform $timeout $easeOutQuint, opacity $timeout $easeOutQuint;
  }

  &.dropInEnterActive,
  &.dropInEnterDone,
  &.dropInExit {
    opacity: 1;
    transform: scale(1);
  }

  &.dropInExitActive {
    transition: transform $timeout $easeOutQuint, opacity $timeout $easeOutQuint;
  }

  &.dropInExitActive,
  &.dropInExitDone {
    opacity: 0;
    transform: scale(1 - $scale-diff);
  }
}
