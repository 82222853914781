.itemCard {
  touch-action: none;
  cursor: grab;
}

.draggingItem {
  border-style: dashed;

  /* stylelint-disable-next-line selector-class-pattern */
  :global(.card-body) {
    opacity: 0;
  }
}

.draggingHtml {
  scroll-behavior: auto;
}
