@import '../assets/styles/shared';

$timeout-color: 250ms * $animationTimeoutFactor;
$timeout-line: 333ms * $animationTimeoutFactor;

.animatedLink {
  &:hover,
  &:global(.active) {
    color: $text-color-base !important;
    text-decoration: none !important;
  }
}

.animatedLinkText:not(:disabled):not(:global(.disabled)) {
  display: block;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &::before {
    position: absolute;
    left: 0;
    z-index: 1;

    width: 0%;
    overflow: hidden;

    color: $navbar-dark-hover-color;
    white-space: nowrap;

    content: attr(data-name);

    transition: width $timeout-color $easeOutCirc, opacity $timeout-color $easeOutCirc;
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 0;

    width: 100%;
    height: 2px;

    content: '';

    background: $light-green;

    transition: width $timeout-line $easeOutCirc, transform $timeout-line $easeOutCirc,
      opacity $timeout-line $easeOutCirc;
    transform: translate3d(-105%, -100%, 0);
  }

  &:hover,
  &:global(.active) {
    &::before {
      width: 100%;
    }

    &::after {
      opacity: 1;
      transform: translate3d(105%, -100%, 0);
    }
  }
}

// stylelint-disable-next-line selector-class-pattern
:global(.navbar-light) {
  .animatedLink {
    &:hover,
    &:global(.active) {
      color: $navbar-light-color !important;
      text-decoration: none !important;
    }
  }

  .animatedLinkText:not(:disabled):not(:global(.disabled)) {
    &::before {
      color: $navbar-light-hover-color !important;
    }
  }
}
