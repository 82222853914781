@use 'sass:list';
@use 'sass:map';

@import '../../assets/styles/shared';

$expand-breakpoint: lg;

// Calculate collapse breakpoint
$breakpoints: map.keys($grid-breakpoints);
$expand-breakpoint-index: list.index($breakpoints, $expand-breakpoint);
$collapse-breakpoint: list.nth($breakpoints, $expand-breakpoint-index - 1);
$toggle-button-timeout: 1000ms * $animationTimeoutFactor;
$sidebar-menu-timeout: 500ms * $animationTimeoutFactor;
$menu-link-offset: 125ms * $animationTimeoutFactor;

:export {
  expandBreakpoint: $expand-breakpoint;
  toggleButtonTimeout: strip-unit($toggle-button-timeout);
  sidebarMenuTimeout: strip-unit($sidebar-menu-timeout);
  menuLinkOffset: strip-unit($menu-link-offset);
}

.navbar {
  @include media-breakpoint-up($expand-breakpoint) {
    // stylelint-disable-next-line selector-class-pattern
    :global(.nav-item) {
      margin: 0 0.75rem;
    }
  }

  // stylelint-disable-next-line selector-class-pattern
  :global(.nav-item) {
    &:first-child {
      margin-left: 0;

      // stylelint-disable-next-line selector-class-pattern
      :global(.nav-link) {
        padding-left: 0;
      }
    }

    &:last-child {
      margin-right: 0;

      // stylelint-disable-next-line selector-class-pattern
      :global(.nav-link) {
        padding-right: 0;
      }
    }
  }
}

.sidebarMobile {
  @include media-breakpoint-down($collapse-breakpoint) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-fixed;

    display: flex;

    flex-direction: column;

    align-items: center;

    justify-content: center;

    width: 100%;
    height: 100vh;
    margin-left: -100%;
    overflow-x: hidden;

    background-color: $nord-blue;

    &.show {
      margin-left: 0;
    }

    // stylelint-disable-next-line selector-class-pattern
    :global(.nav-item) {
      opacity: 0;
      transform: translateY(3rem);
    }

    // Transitions enter
    &.sidebarMobileEnter {
      transition: transform $sidebar-menu-timeout $easeInOutCirc;

      // stylelint-disable-next-line selector-class-pattern
      :global(.nav-item) {
        transition: all $sidebar-menu-timeout $easeOutQuad;
      }
    }

    // State in
    &.sidebarMobileEnterActive,
    &.sidebarMobileEnterDone {
      transform: translateX(100%);

      // stylelint-disable-next-line selector-class-pattern
      :global(.nav-item) {
        opacity: 1;
        transform: translateY(0);
      }
    }

    // Transitions exit
    &.sidebarMobileExit {
      transition: transform $sidebar-menu-timeout $easeInOutCirc $sidebar-menu-timeout;

      // stylelint-disable-next-line selector-class-pattern
      :global(.nav-item) {
        transition: all $sidebar-menu-timeout $easeInQuad;
      }
    }
  }

  @include media-breakpoint-up($expand-breakpoint) {
    transition: none !important;
    transform: none;

    // stylelint-disable-next-line selector-class-pattern
    :global(.nav-item) {
      transition: none !important;
      transform: none;
    }
  }
}

.toggleButton {
  $toggle-button-fragment-timeout: $toggle-button-timeout * 0.25;

  position: relative;
  z-index: $zindex-fixed + 5;

  display: flex;

  flex-direction: column;

  justify-content: space-between;

  width: 2rem;
  height: 1rem;
  padding: 0;
  margin: 1.5rem 0;

  user-select: none;

  background: none;
  border: none;

  @include media-breakpoint-up($expand-breakpoint) {
    display: none;
  }

  &:focus {
    outline: 0;
  }

  .firstLine,
  .middleLine,
  .lastLine {
    width: 2rem;
  }

  .line {
    height: 2px;
    background: $text-color-base;
  }

  // Transitions enter
  &.toggleButtonEnter {
    .firstLine,
    .lastLine {
      .lineCollapse {
        transition: transform $toggle-button-fragment-timeout $easeOutQuad;
      }

      .lineRotate {
        transition: transform $toggle-button-fragment-timeout * 3 $easeOutBack
          $toggle-button-fragment-timeout;
      }

      .line {
        transition: all $toggle-button-fragment-timeout * 2 $easeOutQuad
          $toggle-button-fragment-timeout;
      }
    }

    .middleLine {
      transition: opacity 0ms linear $toggle-button-fragment-timeout;
    }
  }

  // State in
  &.toggleButtonEnterActive,
  &.toggleButtonEnterDone {
    .firstLine,
    .lastLine {
      .line {
        background-color: $white;
        transform: scaleX(0.5);
      }
    }

    .firstLine {
      .lineCollapse {
        transform: translateY(calc(0.5rem - 50%));
      }

      .lineRotate {
        transform: rotate(135deg);
      }
    }

    .lastLine {
      .lineCollapse {
        transform: translateY(calc(-0.5rem + 50%));
      }

      .lineRotate {
        transform: rotate(-135deg);
      }
    }

    .middleLine {
      opacity: 0;
    }
  }

  // Transitions exit
  &.toggleButtonExit {
    .firstLine,
    .lastLine {
      .lineCollapse {
        transition: transform $toggle-button-fragment-timeout $easeInQuad
          $toggle-button-fragment-timeout * 3;
      }

      .lineRotate {
        transition: transform $toggle-button-fragment-timeout * 3 $easeInBack;
      }

      .line {
        transition: all $toggle-button-fragment-timeout * 2 $easeInQuad
          $toggle-button-fragment-timeout;
      }
    }

    .middleLine {
      transition: opacity 0ms linear $toggle-button-fragment-timeout * 3;
    }
  }
}
