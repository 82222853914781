@import '../../../../assets/styles/shared';

/* stylelint-disable selector-class-pattern */

.react-toast-notifications__container {
  z-index: z-index-layer($overlay-layer-index + 2) !important;
}

.react-toast-notifications__toast__content,
.react-toast-notifications__toast__dismiss-button {
  display: flex;
  align-items: center;
  color: $black !important;
}

.react-toast-notifications__toast {
  background-color: $white !important;
}

.react-toast-notifications__toast--info {
  .react-toast-notifications__toast__icon-wrapper {
    background-color: theme-color('info') !important;
  }
}

.react-toast-notifications__toast--success {
  .react-toast-notifications__toast__icon-wrapper {
    background-color: theme-color('success') !important;
  }
}

.react-toast-notifications__toast--warning {
  .react-toast-notifications__toast__icon-wrapper {
    background-color: theme-color('warning') !important;
  }
}

.react-toast-notifications__toast--error {
  .react-toast-notifications__toast__icon-wrapper {
    background-color: theme-color('danger') !important;
  }
}
