@import '../../../styles/shared';

.chartWrapper {
  padding-top: 12rem;

  @include media-breakpoint-up(lg) {
    padding-top: 10rem;
  }
}

.dataWrapper {
  height: 8rem;

  @include media-breakpoint-up(md) {
    height: 10rem;
  }
}
