@import '../../assets/styles/shared';

.portfolioTable {
  border-spacing: 0;
  border-collapse: separate;

  .etfRow {
    transition: transform 0.5s $easeInOutQuad;
  }
}
