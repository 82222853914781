.tooltip {
  text-align: center;
  border: 1px solid rgb(0 0 0 / 10%);

  .tooltipBody {
    padding: 8px;

    .tooltipTitle {
      margin-bottom: 0;
    }
  }
}

// stylelint-disable-next-line selector-class-pattern
:global(.recharts-surface) {
  user-select: none;
}

// stylelint-disable-next-line selector-class-pattern
:global(.recharts-cartesian-axis-ticks) {
  g:first-child {
    text {
      text-anchor: start;
    }
  }

  g:last-child {
    text {
      text-anchor: end;
    }
  }
}
