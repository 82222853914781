@import '../assets/styles/shared';

$timeout: 750ms * $animationTimeoutFactor;
$timeout-offset: 125ms * $animationTimeoutFactor;

:export {
  timeout: $timeout;
  timeout-offset: strip-unit($timeout-offset);
}

.fullPageTransition {
  position: fixed;
  top: 0;
  left: 0;
  z-index: z-index-layer($overlay-layer-index);

  width: 100%;
  height: 100%;

  pointer-events: none;

  .layer {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    transform: translateX(0%);

    &.layerSand {
      background: $sand;
    }

    &.layerWhite {
      background: rgb(255 255 255 / 100%);
    }
  }

  // Transitions Exit
  &.fullPageTransitionExit {
    .layerSand {
      transform: translateX(100%);
    }

    .layerWhite {
      transform: translateX(100%);
    }
  }

  &.fullPageTransitionExitActive {
    .layerSand {
      transition: transform ($timeout - $timeout-offset) $easeInQuint;
    }

    .layerWhite {
      transition: transform $timeout $easeInQuint;
    }
  }

  // State in
  &.fullPageTransitionExitActive,
  &.fullPageTransitionEnter {
    .layerSand {
      transform: translateX(0%);
    }

    .layerWhite {
      transform: translateX(0%);
    }
  }

  // Transitions Enter
  &.fullPageTransitionEnter {
    .layerWhite {
      transition: transform $timeout $easeOutQuint;
    }
  }

  &.fullPageTransitionEnterActive {
    .layerSand {
      transform: translateX(-100%);
    }

    .layerWhite {
      transform: translateX(-100%);
    }
  }

  &.fullPageTransitionEnterDone {
    visibility: hidden !important;
  }
}
