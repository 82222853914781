.logo {
  padding: 1.5rem 0;
  text-align: center;

  img {
    width: 97px;

    @include media-breakpoint-up(sm) {
      width: 109px;
    }

    @include media-breakpoint-up(md) {
      width: 121px;
    }
  }
}
